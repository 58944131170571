<template lang="">
<div class="settingContent">
    <div class="handleBox flex flex-jc-sb flex-ai-c">
        <h3 style="">{{$route.name}}</h3>
        <!-- <el-button type="primary" size="small" @click="handleEdit()">保存</el-button> -->
    </div>

    <div class="contentBox" style="width:100%">
        <!-- <el-card class="box-card"> -->
        <div class="box2 flex flex-ai-c">
            <p style="width:130px">月开会区间</p>
            <div class="flex flex-ai-c">
                <el-radio-group v-model="parameter.meet_month_status">
                    <el-radio :label="1">本月</el-radio>
                    <el-radio :label="2">上月</el-radio>
                </el-radio-group>
                <!-- <el-input v-model.number="parameter.meet_month_from" placeholder="几号" oninput="value=value.replace(/[^\.\d]/g,'')">
                    <template slot="append">号</template>
                </el-input> -->
                
                <!-- <p class="p">-</p> -->
                <!-- <el-input v-model.number="parameter.meet_month_to" placeholder="几号" oninput="value=value.replace(/[^\.\d]/g,'')">
                    <template slot="append">号</template>
                </el-input> -->
            </div>
        </div>
        <div class="box2 flex flex-ai-c">
            <p style="width:130px">周开会区间</p>
            <div class="flex flex-ai-c">
                <el-radio-group v-model="parameter.meet_week_status">
                    <el-radio :label="1">本周</el-radio>
                    <el-radio :label="2">上周</el-radio>
                </el-radio-group>
                <!-- <el-select v-model="parameter.meet_week_from" placeholder="周几" style="width:100%">
                    <el-option v-for="(item,index) in departmentList" :label="item.name" :value="item.id" :key="index">
                    </el-option>
                </el-select>
                <p class="p">-</p>
                <el-select v-model="parameter.meet_week_to" placeholder="周几" style="width:100%">
                    <el-option v-for="(item,index) in departmentList" :label="item.name" :value="item.id" :key="index">
                    </el-option>
                </el-select> -->
            </div>
        </div>

        <div class="box2 flex flex-ai-c">
            <p style="width:130px">月提醒区间</p>
            <div class="flex flex-ai-c">
                <el-input v-model.number="parameter.remind_month_from" placeholder="几号" oninput="value=value.replace(/[^\.\d]/g,'')">
                    <template slot="append">号</template>
                </el-input>
                <p class="p">-</p>
                <el-input v-model.number="parameter.remind_month_to" placeholder="几号" oninput="value=value.replace(/[^\.\d]/g,'')">
                    <template slot="append">号</template>
                </el-input>
                <p class="p">-</p>
                <el-time-picker v-model="parameter.remind_month_time" value-format="HH:mm:ss" placeholder="选择时间">
                </el-time-picker>
            </div>
        </div>
        <div class="box2 flex flex-ai-c">
            <p style="width:130px">周提醒区间</p>
            <div class="flex flex-ai-c">
                <el-select v-model="parameter.remind_week_from" placeholder="周几" style="width:100%">
                    <el-option v-for="(item,index) in departmentList" :label="item.name" :value="item.id" :key="index">
                    </el-option>
                </el-select>
                <p class="p">-</p>
                <el-select v-model="parameter.remind_week_to" placeholder="周几" style="width:100%">
                    <el-option v-for="(item,index) in departmentList" :label="item.name" :value="item.id" :key="index">
                    </el-option>
                </el-select>
                <p class="p">-</p>
                <el-time-picker v-model="parameter.remind_week_time" value-format="HH:mm:ss" placeholder="选择时间">
                </el-time-picker>
            </div>
        </div>
        <div class="box2 flex flex-ai-c">
            <p style="width:400px">月计划完成提前
                <el-input style="width:150px" v-model.number="parameter.month_advance_time" oninput="value=value.replace(/[^\.\d]/g,'')">
                    <template slot="append">天</template>
                </el-input>
                 提醒</p>
        </div>
		<div class="box2 flex flex-ai-c">
            <p style="width:400px">周计划完成提前
                <el-input style="width:150px" v-model.number="parameter.week_advance_time" oninput="value=value.replace(/[^\.\d]/g,'')">
                    <template slot="append">小时</template>
                </el-input>
            提醒</p>
            <p style="width:400px">周计划完成提前
                <el-input style="width:150px" v-model.number="parameter.week_advance_time1" oninput="value=value.replace(/[^\.\d]/g,'')">
                    <template slot="append">小时</template>
                </el-input>
            提醒</p>
            <p style="width:350px">周计划完成提前
                <el-input style="width:150px" v-model.number="parameter.week_advance_time2" oninput="value=value.replace(/[^\.\d]/g,'')">
                    <template slot="append">小时</template>
                </el-input>
            提醒</p>
            <p><i class="el-icon-info" style="color:#e6a23c;margin-right:5px;"></i>时间值依次递减</p>
        </div>
        
        <el-button type="primary" style="width: 680px;margin-top:40px" @click="onSubmit">保存</el-button>
    </div>

</div>
</template>
<script>
export default {
    data() {
        return {
            parameter: {},
            departmentList: [{
                name: '星期一',
                id: '1'
            }, {
                name: '星期二',
                id: '2'
            }, {
                name: '星期三',
                id: '3'
            }, {
                name: '星期四',
                id: '4'
            }, {
                name: '星期五',
                id: '5'
            }, {
                name: '星期六',
                id: '6'
            }, {
                name: '星期日',
                id: '7'
            }]
        };
    },
    mounted() {
        this.getRead()
    },
    methods: {
        getRead() {
            this.http.post('/admin.sysRemind/remindAndMeetRead').then(re => {
                re.meet_week_from = String(re.meet_week_from);
                re.meet_week_to = String(re.meet_week_to);
                re.remind_week_from = String(re.remind_week_from);
                re.remind_week_to = String(re.remind_week_to);
                this.parameter = re;
            })
        },
        onSubmit() {
            this.http.post('/admin.sysRemind/remindAndMeetSave', this.parameter).then(() => {
                this.$message({
                    message: '保存成功',
                    type: 'success'
                })
            })
        },
    },
};
</script>
<style lang="less">
.el-tabs__content {
    height: calc(100% - 70px);

    > div {
        height: 100%;
    }
}

.el-tab-pane {
    height: 100%;
}
.box2 {
    margin-top: 20px;
    > p {
        color: #666;
        font-size: 14px;
    }
    div {
        .el-input,
        .el-select {
            width: 150px !important;
        }
    }
    .p {
        width: 50px;
        text-align: center;
    }
}
</style>
